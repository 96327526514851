import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth';
import {getFirestore, collection, doc} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDJu64fusvN9H217_VxYwuqajQg6yQ8qGY",
    authDomain: "chat-clone-163ec.firebaseapp.com",
    projectId: "chat-clone-163ec",
    storageBucket: "chat-clone-163ec.appspot.com",
    messagingSenderId: "873476985218",
    appId: "1:873476985218:web:ec80e053ddc3108e7bff9b",
    measurementId: "G-3MVTWNELER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore();
export const usersRef = collection(db, 'users');
export const keysRef = doc(db, 'keys', 'openai-api');
export const auth = getAuth(app);
