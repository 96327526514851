import styled from "@emotion/styled";
import {Button, Image, MANTINE_COLORS, Modal, PasswordInput, TextInput} from "@mantine/core";
import React, {useCallback, useState} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppDispatch } from "../store";
import { closeModals, openSignupModal } from "../store/ui";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../global-options/firebase-config";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../core/authContext";


const Container = styled.form`
    * {
        font-family: "Work Sans", sans-serif;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .mantine-TextInput-root, .mantine-PasswordInput-root {
        margin-top: 1rem;
    }
    
    .mantine-TextInput-root + .mantine-Button-root,
    .mantine-PasswordInput-root + .mantine-Button-root {
        margin-top: 1.618rem;
    }

    .mantine-Button-root {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0.25rem;
    }
`;

export function LoginModal(props: any) {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [formData, setFormData] = useState({email: '', password: ''});
    const navigate = useNavigate();
    const [err, setErr] = useState('');

    const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
    const onCreateAccountClick = useCallback(() => dispatch(openSignupModal()), [dispatch]);
    const authContext = useAuth();

    const onSignIn = async (e) => {
        authContext.setIsLoading(true);
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            navigate('/');
        } catch (e: any) {
            if (e.message === 'Firebase: Error (auth/invalid-login-credentials).') {
                return setErr('Incorrect login credentials. Please try again.');
            }

            if (e.message === 'Firebase: Error (auth/invalid-email).') {
                return setErr('No such account. Ask admin to add you to the system.');
            }

            if (e.message === 'Firebase: Error (auth/missing-password).') {
                return setErr('Provide the password.');
            }

            setErr(e.message);
        }
    };

    return <Modal color={MANTINE_COLORS[0]} opened={true} onClose={onClose} withCloseButton={false}>
        <Container>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Image style={{
                    width: 100,
                    height: 100,
                    marginBottom: -20,
                }} src={'https://framerusercontent.com/images/rtjMbArNIA7Ixpq0l7IGVMIkY4.svg'}/>
            </div>
            <input type="hidden" name="redirect_url" value={window.location.href} />
            <TextInput
                color={MANTINE_COLORS[0]}
                label={intl.formatMessage({ defaultMessage: "Email address" })}
                name="username"
                onChange={e => setFormData({...formData, email: e.target.value})}
                placeholder={intl.formatMessage({ defaultMessage: "Enter your email address" })}
                type="email"
                required />
            <PasswordInput
                color={MANTINE_COLORS[0]}
                label={intl.formatMessage({ defaultMessage: "Password" })}
                name="password"
                onChange={e => setFormData({...formData, password: e.target.value})}
                placeholder={intl.formatMessage({ defaultMessage: "Enter your password" })}
                maxLength={500}
                required />
            <Button                 color={MANTINE_COLORS[0]}
                                    onClick={onSignIn} fullWidth type="submit">
                <FormattedMessage defaultMessage={"Sign in"} />
            </Button>
            {err && <p style={{textAlign: 'center', fontSize: 14, color: 'red', marginTop: 8}}>{err}</p>}
        </Container>
    </Modal>
}

// export function CreateAccountModal(props: any) {
//     const modal = useAppSelector(selectModal);
//     const dispatch = useAppDispatch();
//     const intl = useIntl();
//
//     const onClose = useCallback(() => dispatch(closeModals()), [dispatch]);
//     const onSignInClick = useCallback(() => dispatch(openLoginModal()), [dispatch]);
//
//     return <Modal opened={modal === 'signup'} onClose={onClose} withCloseButton={false}>
//         <Container action="/chatapi/register" method="post">
//             <h2>
//                 <FormattedMessage defaultMessage={"Create an account"} />
//             </h2>
//             <input type="hidden" name="redirect_url" value={window.location.href} />
//             <TextInput
//                 label={intl.formatMessage({ defaultMessage: "Email address" })}
//                 name="username"
//                 placeholder={intl.formatMessage({ defaultMessage: "Enter your email address" })}
//                 type="email"
//                 required />
//             <PasswordInput
//                 label={intl.formatMessage({ defaultMessage: "Password" })}
//                 name="password"
//                 placeholder={intl.formatMessage({ defaultMessage: "Enter your password" })}
//                 minLength={6}
//                 maxLength={500}
//                 required />
//             <Button fullWidth type="submit">
//                 <FormattedMessage defaultMessage={"Sign up"} />
//             </Button>
//             <Button fullWidth variant="subtle" onClick={onSignInClick}>
//                 <FormattedMessage defaultMessage={"Or sign in to an existing account"} description="Label for a button on the Create Account page that lets the user sign into their existing account instead" />
//             </Button>
//         </Container>
//     </Modal>
// }
