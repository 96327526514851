import {createContext, useContext, useEffect, useState} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth, usersRef} from "../global-options/firebase-config";
import { getDocs } from 'firebase/firestore';

const AuthContext = createContext({user: null, key: '', isLoading: true, roles: [], error: null, setError: () => {}});
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState<any>(null);
    const [key, setKey] = useState('');
    const [ss, dd] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState(null);

    // change 2
    // change 3

    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
           setUser(currentUser);

           if (currentUser === null) {
               setIsLoading(false);
           }

           // change 1


            if (currentUser) {
               getDocs(usersRef).then(snapshot => {
                   let users: any[] = [];
                   snapshot.docs.forEach(doc => {
                       users.push({...doc.data(), id: doc.id});
                   })

                   let foundUser = users.find(item => item.id === currentUser.email)

                   if (foundUser) {
                       setRoles(foundUser.roles);
                       if (foundUser.openAIKey) {
                           setKey(foundUser.openAIKey);
                           localStorage.setItem('openai-api-key', foundUser.openAIKey);
                       } else {
                           localStorage.removeItem('openai-api-key');
                       }
                   }
               }).finally(() => {
                   setIsLoading(false);
               })
           }
        })
    }, []);

    return (
        <AuthContext.Provider value={{ user, isLoading, roles, error, setError, key, setKey, setIsLoading }}>
                {children}
    </AuthContext.Provider>
);
};


export const useAuth = () => useContext(AuthContext);
